
.button {
  padding: .5rem 1rem;
  border-radius: 2rem;
}

/* Primary */
.primary {
  color: var(--color-white);
  background-color: var(--color-primary);
  border: solid 2px var(--color-primary);
}

.primary:hover {
  color: var(--color-black);
  background-color: var(--color-white);
  border: solid 2px var(--color-primary);

  transition: var(--transition);
}

/* Secondary */
.secondary {
  color: var(--color-black);
  background-color: var(--color-white);
  border: solid 2px var(--color-black);
}

.secondary:hover {
  color: var(--color-white);
  background-color: var(--color-primary);
  border: solid 2px var(--color-primary);

  transition: var(--transition);
}

/* Disabled */
.button:disabled {
  cursor:not-allowed;
  color: var(--color-grey-dark);
  background-color: var(--color-grey-light);
  border: solid 2px var(--color-grey-light);
}
