.footer {
  background-color: var(--color-black);
  padding: 2rem;
}

.footerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logoWrapper {
  width: 8rem;
  height: 8rem;
  background-color: springgreen;
}

.divider {
  width: 30rem;
  border-top: solid 2px var(--color-primary);
}

.footerContact {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footerContact a {
  text-decoration: none;
  color: var(--color-white);
  margin-bottom: .5rem;
}

.footerContact a:last-child {
  margin-bottom: 0;
}

.footerContact a:hover {
  color: var(--color-primary);
  transition: var(--transition);
}