.stepsContainer {
  border-top: solid .8rem var(--color-primary-light);
  box-shadow: var(--box-shadow);
  background-color: var(--color-white);
}

.stepWrapper {
  padding: 2rem;
  text-align: center;
}

.logoWrapper {
  text-align: center;
}

.logoWrapper svg {
  background-color: var(--color-primary-light);
  color: var(--color-white);
  padding: 10px;
  border-radius: 49%;
  margin-bottom: .5rem;
  font-size: 3rem;
}