.headerWrapper {
  color: var(--color-white);
  padding: 1rem 0;
  position: absolute;
  width: 90vw;
  top: 2rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99;
}

.logoWrapper {
  width: 100px;
  height: 100px;
  background-color: springgreen;
}

.border {
  display: flex;
  align-items: center;
}

.navWrapper {
  width: 100%;
  margin: 0px;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.navWrapper > li > a{
  text-transform: uppercase;
  text-decoration: none;
  color: var(--color-white);
  text-shadow: var(--text-shadow);
}

.navWrapper > li > a:hover{
  color: var(--color-primary-light);
  transition: var(--transiton);
}

.languagesWrapper {
  /* border: solid 2px red; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.languagesWrapper span{
  text-shadow: 0px 2px 5px var(--color-black);
  text-transform: uppercase;
  cursor: pointer;
}

.activeLang {
  color: var(--color-primary);
}

.languagesWrapper span:first-child {
  margin-right: 1rem;
}

@media (max-width: 900px) {
  .logoWrapper {
    margin-bottom: 1rem;
    width: 50px;
    height: 50px;
  }

  .languagesWrapper {
    margin-top: 1rem;
  }
}